@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap);
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    background-color: #293241;
    overflow-x: hidden;
}
.container {
    width: 100%;
    min-height: 100vh;
}
main {
    width: 90%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.header {
    width: 100%;
    height: 100px;
    position: relative;
    border-bottom: 1px solid rgba(156, 156, 156, 0.246);
}
.navbar {
    max-width: 1200px;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo {
    background-color: #fff;
    padding: 0.7rem;
    border-radius: 5px;
}
.logo-brand {
    font-size: 2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    color: #293241;
}
.nav-items {
    position: absolute;
    right: -300%;
    top: 100px;
    width: 300px;
    height: 100vh;
    background-color: transparent;
    list-style: none;
    z-index: 20;
    transition: right 0.3s ease;
    background-color: #fff;
    border-top-left-radius: 10%;
}
.nav-items.show {
    right: 0;
    transition: 0.3s ease;
}
.nav-item {
    width: 100%;
    padding: 1rem;
    margin-bottom: 10px;
    transition: 0.3s ease;
}
.nav-item:hover {
    background-color: #293241;
}
.nav-item:hover .nav-link {
    color: #fff;
}
.nav-link {
    text-decoration: none;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: #333;
    display: block;
    text-align: center;
}
.toggle-menu {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
}
.menu-icon {
    font-size: 1.6rem;
}

.navigation-card {
    width: 500px;
    height: 500px;
    margin: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    position: relative;
}
.about {
    background-image: linear-gradient(
        to right bottom,
        #0061ff,
        #008fff,
        #00b4ff,
        #00d3ff,
        #60efff
    );
}
.project {
    background-image: linear-gradient(
        to left top,
        #f89b29,
        #ff7f36,
        #ff6049,
        #ff3d61,
        #ff0f7b
    );
}
.contact {
    background-image: linear-gradient(
        to left,
        #ef476f,
        #cd3653,
        #ab263a,
        #8a1524,
        #6a040f
    );
}
.resume {
    background-image: linear-gradient(
        to right top,
        #1780a1,
        #3071ab,
        #625ca4,
        #8b3f87,
        #a01a58
    );
}
.inner-content h1 {
    font-size: 2.7rem;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 1rem;
    color: #fff;
}
.inner-content .link {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 2.5rem;
    color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.47);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.inner-content .link:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.inner-content .link .link-icon {
    color: #ff0a54;
}
.resume-btn {
    padding: 14px 2rem;
    width: 100%;
    border-radius: 20px;
    border: 2px solid #fff;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    transition: box-shadow 0.3s ease;
}
.resume-btn a {
    font-size: 1.2rem;
    text-transform: capitalize;
    color: #fff;
    letter-spacing: 1px;
    text-decoration: none;
}
.resume-btn:hover {
    box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.164);
}

.about-title {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.63);
    margin: 3rem 0;
}
.about-me-box {
    width: 100%;
    display: flex;
    border: 1px solid rgba(156, 156, 156, 0.246);
    border-radius: 5px;
    overflow: hidden;
    background-image: linear-gradient(
        to right bottom,
        #0061ff,
        #008fff,
        #00b4ff,
        #00d3ff,
        #60efff
    );
    box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.47);
}
.about-me-text {
    width: 50%;
    height: 100%;
    padding: 5% 2%;
    line-height: 1.6;
    color: #fff;
}
.about-me-text p {
    padding-bottom: 10px;
}
.image-box {
    width: 50%;
    height: 100%;
}
.image-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.frontend,
.backend {
    width: 100%;
    height: 350px;
    display: flex;
    border: 1px solid rgba(156, 156, 156, 0.246);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 5rem;
}
.frontend .left {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        to left top,
        #f89b29,
        #ff7f36,
        #ff6049,
        #ff3d61,
        #ff0f7b
    );
}
.frontend .left h3,
.backend .right h3 {
    font-size: 2.5rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
}
.frontend .right {
    width: 60%;
    height: 100%;
    padding: 1rem;
    list-style: none;
    line-height: 2;
    font-size: 1.4rem;
    background-color: #fff;
}
.frontend .right .frontend-item {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin: 10px;
}
.frontend .right .frontend-item img,
.backend .left .backend-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.backend .left {
    width: 60%;
    height: 100%;
    padding: 1rem;
    list-style: none;
    line-height: 2;
    font-size: 1.4rem;
    background-color: #fff;
    overflow-x: scroll;
}
.backend .right {
    background-image: linear-gradient(
        to right bottom,
        #0061ff,
        #008fff,
        #00b4ff,
        #00d3ff,
        #60efff
    );
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backend .left .backend-item {
    width: 300px;
    height: auto;
    display: inline-block;
    margin: 10px;
}

@media screen and (max-width: 784px) {
    .about-me-box {
        flex-direction: column;
        height: auto;
    }
    .about-me-text {
        width: 100%;
        order: 2;
    }
    .image-box {
        width: 100%;
        order: 1;
    }
    .frontend,
    .backend {
        flex-direction: column;
        height: auto;
    }
    .frontend .left {
        width: 100%;
        height: 300px;
    }
    .frontend .right {
        width: 100%;
    }
    .backend .left {
        width: 100%;
        order: 2;
    }
    .backend .right {
        width: 100%;
        height: 300px;
        order: 1;
    }
}

.project-card {
    width: 400px;
    height: 100%;
    border: 1px solid rgba(156, 156, 156, 0.246);
    border-radius: 5px;
    background-color: #fff;
    margin: 1rem;
    overflow: hidden;
    padding-bottom: 12px;
}
.card-top {
    width: 100%;
    height: 300px;
}
.card-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card-bottom {
    width: 100%;
    padding: 1rem;
}
.card-bottom h4 {
    font-size: 1.7rem;
    color: #333;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-transform: capitalize;
}
.card-bottom p {
    line-height: 1.6;
}
.btn-box {
    width: 100%;
    padding-left: 1rem;
}
.btn-box button {
    padding: 15px 3rem;
    margin-right: 1rem;
    margin-top: 1rem;
    border-radius: 20px;
    border: none;
    outline: none;
    transition: all 0.3s ease;
}
.btn-box button a {
    text-decoration: none;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: #333;
}

.github:hover {
    background-image: linear-gradient(
        to left,
        #ef476f,
        #cd3653,
        #ab263a,
        #8a1524,
        #6a040f
    );
}

.website:hover {
    background-image: linear-gradient(
        to right bottom,
        #0061ff,
        #008fff,
        #00b4ff,
        #00d3ff,
        #60efff
    );
}
@media screen and (max-width: 768px) {
    .project-card {
        width: 100%;
    }
}
@media screen and (max-width: 411px) {
    .btn-box button {
        padding: 15px 1.5rem;
        margin-right: 1rem;
    }
}

.project-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 0;
}

.contact-section {
    width: 100%;
    height: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    width: 50%;
    height: 100%;
    padding: 1rem 1.5rem;
    line-height: 2;
    background-image: linear-gradient(
        to left top,
        #f89b29,
        #ff7f36,
        #ff6049,
        #ff3d61,
        #ff0f7b
    );
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 3rem;
    border-radius: 5px;
    border: 1px solid rgba(156, 156, 156, 0.246);
}
.content .intro-title {
    font-size: 2rem;
}
.form {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-group {
    width: 100%;
    margin-bottom: 1rem;
}
.form-group input {
    width: 100%;
    padding: 15px 1rem;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 1.3rem;
}
.form-group textarea {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 1.2rem;
    font-size: 1.3rem;
    resize: none;
}
.form-group textarea::-webkit-input-placeholder {
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
}
.form-group textarea:-ms-input-placeholder {
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
}
.form-group textarea::placeholder {
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
}
.btn-contact {
    width: 100%;
    padding: 15px 1rem;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 1.3rem;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.btn-contact:hover {
    background-image: linear-gradient(
        to left top,
        #f89b29,
        #ff7f36,
        #ff6049,
        #ff3d61,
        #ff0f7b
    );
    color: #fff;
}
@media screen and (max-width: 784px) {
    .content {
        width: 100%;
    }

    .form {
        width: 100%;
    }
}

.footer {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(156, 156, 156, 0.246);
    margin-top: 2rem;
}
.footer .logo {
    margin-bottom: 1rem;
    background-color: transparent;
    color: #fff;
}
.copyright {
    color: #fff;
    letter-spacing: 1px;
}

