.about-title {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.63);
    margin: 3rem 0;
}
.about-me-box {
    width: 100%;
    display: flex;
    border: 1px solid rgba(156, 156, 156, 0.246);
    border-radius: 5px;
    overflow: hidden;
    background-image: linear-gradient(
        to right bottom,
        #0061ff,
        #008fff,
        #00b4ff,
        #00d3ff,
        #60efff
    );
    -webkit-box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.47);
    box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.47);
}
.about-me-text {
    width: 50%;
    height: 100%;
    padding: 5% 2%;
    line-height: 1.6;
    color: #fff;
}
.about-me-text p {
    padding-bottom: 10px;
}
.image-box {
    width: 50%;
    height: 100%;
}
.image-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.frontend,
.backend {
    width: 100%;
    height: 350px;
    display: flex;
    border: 1px solid rgba(156, 156, 156, 0.246);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 5rem;
}
.frontend .left {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        to left top,
        #f89b29,
        #ff7f36,
        #ff6049,
        #ff3d61,
        #ff0f7b
    );
}
.frontend .left h3,
.backend .right h3 {
    font-size: 2.5rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
}
.frontend .right {
    width: 60%;
    height: 100%;
    padding: 1rem;
    list-style: none;
    line-height: 2;
    font-size: 1.4rem;
    background-color: #fff;
}
.frontend .right .frontend-item {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin: 10px;
}
.frontend .right .frontend-item img,
.backend .left .backend-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.backend .left {
    width: 60%;
    height: 100%;
    padding: 1rem;
    list-style: none;
    line-height: 2;
    font-size: 1.4rem;
    background-color: #fff;
    overflow-x: scroll;
}
.backend .right {
    background-image: linear-gradient(
        to right bottom,
        #0061ff,
        #008fff,
        #00b4ff,
        #00d3ff,
        #60efff
    );
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backend .left .backend-item {
    width: 300px;
    height: auto;
    display: inline-block;
    margin: 10px;
}

@media screen and (max-width: 784px) {
    .about-me-box {
        flex-direction: column;
        height: auto;
    }
    .about-me-text {
        width: 100%;
        order: 2;
    }
    .image-box {
        width: 100%;
        order: 1;
    }
    .frontend,
    .backend {
        flex-direction: column;
        height: auto;
    }
    .frontend .left {
        width: 100%;
        height: 300px;
    }
    .frontend .right {
        width: 100%;
    }
    .backend .left {
        width: 100%;
        order: 2;
    }
    .backend .right {
        width: 100%;
        height: 300px;
        order: 1;
    }
}
