@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    background-color: #293241;
    overflow-x: hidden;
}
.container {
    width: 100%;
    min-height: 100vh;
}
main {
    width: 90%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
