.project-card {
    width: 400px;
    height: 100%;
    border: 1px solid rgba(156, 156, 156, 0.246);
    border-radius: 5px;
    background-color: #fff;
    margin: 1rem;
    overflow: hidden;
    padding-bottom: 12px;
}
.card-top {
    width: 100%;
    height: 300px;
}
.card-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card-bottom {
    width: 100%;
    padding: 1rem;
}
.card-bottom h4 {
    font-size: 1.7rem;
    color: #333;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-transform: capitalize;
}
.card-bottom p {
    line-height: 1.6;
}
.btn-box {
    width: 100%;
    padding-left: 1rem;
}
.btn-box button {
    padding: 15px 3rem;
    margin-right: 1rem;
    margin-top: 1rem;
    border-radius: 20px;
    border: none;
    outline: none;
    transition: all 0.3s ease;
}
.btn-box button a {
    text-decoration: none;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: #333;
}

.github:hover {
    background-image: linear-gradient(
        to left,
        #ef476f,
        #cd3653,
        #ab263a,
        #8a1524,
        #6a040f
    );
}

.website:hover {
    background-image: linear-gradient(
        to right bottom,
        #0061ff,
        #008fff,
        #00b4ff,
        #00d3ff,
        #60efff
    );
}
@media screen and (max-width: 768px) {
    .project-card {
        width: 100%;
    }
}
@media screen and (max-width: 411px) {
    .btn-box button {
        padding: 15px 1.5rem;
        margin-right: 1rem;
    }
}
