.footer {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(156, 156, 156, 0.246);
    margin-top: 2rem;
}
.footer .logo {
    margin-bottom: 1rem;
    background-color: transparent;
    color: #fff;
}
.copyright {
    color: #fff;
    letter-spacing: 1px;
}
