.navigation-card {
    width: 500px;
    height: 500px;
    margin: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    position: relative;
}
.about {
    background-image: linear-gradient(
        to right bottom,
        #0061ff,
        #008fff,
        #00b4ff,
        #00d3ff,
        #60efff
    );
}
.project {
    background-image: linear-gradient(
        to left top,
        #f89b29,
        #ff7f36,
        #ff6049,
        #ff3d61,
        #ff0f7b
    );
}
.contact {
    background-image: linear-gradient(
        to left,
        #ef476f,
        #cd3653,
        #ab263a,
        #8a1524,
        #6a040f
    );
}
.resume {
    background-image: linear-gradient(
        to right top,
        #1780a1,
        #3071ab,
        #625ca4,
        #8b3f87,
        #a01a58
    );
}
.inner-content h1 {
    font-size: 2.7rem;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 1rem;
    color: #fff;
}
.inner-content .link {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 2.5rem;
    color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    -webkit-box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.47);
    box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.47);
    transition: transform 0.3s ease;
}
.inner-content .link:hover {
    transform: scale(1.2);
}
.inner-content .link .link-icon {
    color: #ff0a54;
}
.resume-btn {
    padding: 14px 2rem;
    width: 100%;
    border-radius: 20px;
    border: 2px solid #fff;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    transition: box-shadow 0.3s ease;
}
.resume-btn a {
    font-size: 1.2rem;
    text-transform: capitalize;
    color: #fff;
    letter-spacing: 1px;
    text-decoration: none;
}
.resume-btn:hover {
    -webkit-box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.164);
    box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.164);
}
