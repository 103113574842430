.contact-section {
    width: 100%;
    height: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    width: 50%;
    height: 100%;
    padding: 1rem 1.5rem;
    line-height: 2;
    background-image: linear-gradient(
        to left top,
        #f89b29,
        #ff7f36,
        #ff6049,
        #ff3d61,
        #ff0f7b
    );
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 3rem;
    border-radius: 5px;
    border: 1px solid rgba(156, 156, 156, 0.246);
}
.content .intro-title {
    font-size: 2rem;
}
.form {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-group {
    width: 100%;
    margin-bottom: 1rem;
}
.form-group input {
    width: 100%;
    padding: 15px 1rem;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 1.3rem;
}
.form-group textarea {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 1.2rem;
    font-size: 1.3rem;
    resize: none;
}
.form-group textarea::placeholder {
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
}
.btn-contact {
    width: 100%;
    padding: 15px 1rem;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 1.3rem;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.btn-contact:hover {
    background-image: linear-gradient(
        to left top,
        #f89b29,
        #ff7f36,
        #ff6049,
        #ff3d61,
        #ff0f7b
    );
    color: #fff;
}
@media screen and (max-width: 784px) {
    .content {
        width: 100%;
    }

    .form {
        width: 100%;
    }
}
