.header {
    width: 100%;
    height: 100px;
    position: relative;
    border-bottom: 1px solid rgba(156, 156, 156, 0.246);
}
.navbar {
    max-width: 1200px;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo {
    background-color: #fff;
    padding: 0.7rem;
    border-radius: 5px;
}
.logo-brand {
    font-size: 2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    color: #293241;
}
.nav-items {
    position: absolute;
    right: -300%;
    top: 100px;
    width: 300px;
    height: 100vh;
    background-color: transparent;
    list-style: none;
    z-index: 20;
    transition: right 0.3s ease;
    background-color: #fff;
    border-top-left-radius: 10%;
}
.nav-items.show {
    right: 0;
    transition: 0.3s ease;
}
.nav-item {
    width: 100%;
    padding: 1rem;
    margin-bottom: 10px;
    transition: 0.3s ease;
}
.nav-item:hover {
    background-color: #293241;
}
.nav-item:hover .nav-link {
    color: #fff;
}
.nav-link {
    text-decoration: none;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: #333;
    display: block;
    text-align: center;
}
.toggle-menu {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
}
.menu-icon {
    font-size: 1.6rem;
}
